import { useCallback, useEffect, useRef, useState } from "react";
import { useOutside } from "@hooks/useOutside";
import { Element, scroller } from "react-scroll/modules";
import { Check, SmallArrow } from "@assets/icons";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useDeferredRenderEffect from "@hooks/useDifferredRenderEffects";
import styles from "./Dropdown.module.scss";

const Dropdown = ({
  onChange,
  classSelect,
  label,
  options,
  scrollID = "default",
  error,
  value,
  className,
  placeholder,
  writableDropdown,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [inputValue, setInputValue] = useState(value || "");
  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    if (value === "") {
      setInputValue("");
    }
  }, [value]);

  const wrapperRef = useRef(null);
  useOutside(wrapperRef, setIsOpen);

  const scroll = (fieldName) => {
    scroller.scrollTo(`value-${fieldName}`, {
      duration: 300,
      smooth: true,
      containerId: `dropdown-${scrollID}`,
      offset: -5,
    });
  };

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const viewLabel = useCallback(() => {
    if (value || value === 0) {
      return (
        <span className="text-dawn-gray">
          {localOptions.find((v) => v.value === value)?.label}
        </span>
      );
    }
    if (placeholder) {
      return <span className="text-gray-light">{placeholder}</span>;
    }
    return <span className="text-dawn-gray"> - </span>;
  }, [value, placeholder, options]);

  useDeferredRenderEffect(() => {
    if (value && isOpen) {
      scroll(value);
    }
  }, [value, isOpen]);

  const onClick = (option) => {
    if (!option?.value) {
      return;
    }
    setInputValue(option.label); // Set input value based on the selected option
    onChange(option);
    setIsOpen(false);
  };

  const handleInputChange = (e) => {
    const typedValue = e.target.value;
    setInputValue(typedValue);

    if (!typedValue) {
      setLocalOptions(options); // Reset options when input is cleared
    } else {
      // Filter the options based on the typed value
      const filteredOptions = options.filter((option) =>
        option.label.toLowerCase().includes(typedValue.toLowerCase())
      );
      setLocalOptions(filteredOptions);
    }
  };

  return (
    <div className={clsx("relative", className)}>
      {!!label && <p className="text-cloudy-gray pl-4 mb-3.5">{label}</p>}
      <div
        className={clsx(
          "w-full rounded border border-transparent border-solid flex items-center justify-between px-4 shadow-redShadow cursor-pointer",
          classSelect,
          isOpen ? "pointer-events-none" : "",
          error ? "border-light-red" : ""
        )}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {writableDropdown ? (
          <input
            type="text"
            className="p-2 bg-silver-light text-dawn-gray w-full"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder || "Type or select an option"}
          />
        ) : (
          <span className="w-full p-2">{viewLabel()}</span>
        )}
        <SmallArrow className="transform rotate-90 stroke-current text-dawn-gray" />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={clsx(
              "absolute z-20 flex flex-1 flex-row w-full rounded-sm top-full shadow",
              styles.list
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, translateY: "0.625rem" }}
            exit={{ opacity: 0, translateY: "1.25rem" }}
          >
            <div
              className={clsx(
                "flex flex-col flex-1 bg-white p-1",
                styles.hideWithScrollbar
              )}
              ref={wrapperRef}
              id={`dropdown-${scrollID}`}
            >
              {localOptions.map((item) => (
                <Element
                  key={item.value}
                  name={`value-${item.value}`}
                  className={clsx(
                    "px-2 text-dawn-gray relative rounded-md py-2 cursor-pointer flex flex-row justify-between items-center",
                    value === item.value ? "bg-pampas font-medium-poppins" : ""
                  )}
                  onClick={() => onClick(item)}
                >
                  {item.label}
                  {value === item.value ? <Check className="mb-1" /> : <></>}
                </Element>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default Dropdown;
