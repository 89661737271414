import Auth from "@pages/Auth";
import {
  AUTH_ROOT_FORGOT,
  NOT_FOUND,
  ROOT,
  AUTH_ROOT_SSO,
  AUTH_ROOT_SIGN_UP_CONSUMER,
  AUTH_ROOT_LOGIN,
  EXTERNAL_USER,
  VIDEO_ROOM,
  QUESTIONS,
} from "@router/consts";
import { RedirectSurvey } from "@pages/RedirectPage";
import Root from "@pages/Root";

export const AUTH_ROUTES = [
  {
    name: "Login",
    path: AUTH_ROOT_LOGIN,
    exact: true,
    element: <Auth.Login />,
  },
  {
    name: "signUpConsumer",
    path: AUTH_ROOT_SIGN_UP_CONSUMER,
    exact: true,
    element: <Auth.SignUpConsumer />,
  },
  {
    name: "forgot",
    path: AUTH_ROOT_FORGOT,
    exact: true,
    element: <Auth.Forgot />,
  },
  {
    name: "sso",
    path: AUTH_ROOT_SSO,
    exact: true,
    element: <Auth.Sso />,
  },
  {
    name: "welcome",
    path: ROOT,
    exact: true,
    element: <Auth.Welcome />,
  },
  {
    name: "externalUser",
    path: EXTERNAL_USER,
    exact: true,
    element: <Auth.ExternalUser />,
  },
  {
    name: "videoRoom",
    path: VIDEO_ROOM,
    element: <Root.VideoRoom />,
  },
  {
    name: "not-found",
    path: NOT_FOUND,
    exact: true,
    element: <RedirectSurvey />,
  },
  {
    name: "surveyQuestions",
    path: QUESTIONS,
    exact: true,
    element: <Auth.Survey />,
  },
];
